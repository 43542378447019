<template>
  <a-modal
    okText="确认"
    cancelText="取消"
    :visible="visible"
    title="批量删除"
    centered
    width="390px"
    @cancel="closeModal"
    @ok="handleOk"
  >
    <div class="deleteMsg">
      <p class="text-md text-dark">确认删除 "<span class="text-danger">{{ name }}</span>" 组</p>
      <p class="text-danger">删除操作不可逆</p>
    </div>
  </a-modal>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { deleteGroup } from '@/api/SelectGoods'

/**
 * @author  XuHongli
 * @date  2022/12/2 16:02
 * @version 1.0
 * @description 批量删除 商品库中 的商品
 */
export default {
  name: 'DeleteGroupModal',
  mixins: [ ShowModalMixin ],
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    handleOk() {
      deleteGroup(this.id).then((res) => {
        this.$message.success(res.msg)
        this.$emit('ok',true)
        this.closeModal()
      })
    }
  }
}
</script>

<style scoped>

</style>
