<template>
  <div class="ShopLibrary bg-white p-10">
    <h6 class="flex-row flex-row-between">
      <p class="common-tip-title">我的选品库</p>
      <a-button size="small" type="primary" style="margin-left: auto" @click="handleAsync">一键同步</a-button>
    </h6>
    <a-spin tip="Loading..." :spinning="loading">
      <div class="library">
        <a-card
          class="groupCard mr-10 mb-10"
          :hoverable="true"
          @click="handleAddGroupCard"
        >
          <div class="flex-column flex-col-center flex-row-center mt-20" style="height: 100%">
            <template v-if="!showAddGroup">
              <img src="https://img.alicdn.com/tfs/TB1aurhc.z1gK0jSZLeXXb9kVXa-156-156.png" alt="">
              <strong>添加分组</strong>
            </template>
            <template v-if="showAddGroup">
              <div class="p-10">
                <a-input
                  size="small"
                  class="mb-10"
                  v-model.trim="addGroupName"
                  :placeholder="addGroupPlaceholder"></a-input>
                <a-button type="primary" size="small" class="mr-5" @click="addGroup">确定</a-button>
                <a-button type="info" size="small" @click.stop="cancel">取消</a-button>
              </div>
            </template>
          </div>
        </a-card>
        <a-card
          class="groupCard mr-10 mb-10"
          :hoverable="true"
          v-for="(item, index) in GroupList"
          :key="index"
          @click="$router.push('/user/ShopLibrary/GroupDetail/' + item.id)"
          :title="item.name">
          <a slot="extra" href="#" class="text-danger" @click.stop="deleteGroup(item.id, item.name)">删除</a>
          <p class="groupCard-count">{{ `商品数量: ${item.count}` }}</p>
          <div class="flex-row">
            <img v-for="(url, i) in item.imgUrls" :key="i" :src="url" alt="">
          </div>
        </a-card>
      </div>
    </a-spin>
    <DeleteGroupModal
      v-model="showDeleteGroupModal"
      :id="selectGroupId"
      @ok="init"
      :name="selectGroupName">
    </DeleteGroupModal>
    <SyncGroupGoodsModal v-model="showSyncGroupGoodsModal"></SyncGroupGoodsModal>
  </div>
</template>

<script>
import { addGroup, getGroupList, syncGoods } from '@/api/SelectGoods'
import DeleteGroupModal from '@/components/Modal/DeleteGroupModal'
import moment from 'moment'
import SyncGroupGoodsModal from '@/components/Modal/SyncGroupGoodsModal'
/**
 * @author  XuHongli
 * @date  2022/12/2 12:53
 * @version 1.0
 * @description 选品库
 */
export default {
  name: 'ShopLibrary',
  components: { SyncGroupGoodsModal, DeleteGroupModal },
  data() {
    return {
      showAddGroup: false,
      // 添加组的名称
      addGroupName: '',
      // 默认的placeholder
      addGroupPlaceholder: '',
      // 页面加载
      loading: false,
      // 组列表
      GroupList: [],
      // 选中组的名称
      selectGroupName: '',
      // 选中组的 ID
      selectGroupId: '',
      // 确认删除弹窗
      showDeleteGroupModal: false,
      // 同步(组)弹窗
      showSyncGroupGoodsModal: false,
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 加载数据
    init() {
      this.loading = true
      getGroupList().then((res) => {
        this.GroupList = res.data
        this.loading = false
      })
    },
    // 添加分组的取消
    cancel() {
      this.showAddGroup = false
      this.addGroupName = ''
    },
    // 点击添加分组卡片
    handleAddGroupCard() {
      this.addGroupPlaceholder = `创建于 ${moment().format('YYYY-MM-DD HH:mm:ss')}`
      this.showAddGroup = true
    },
    // 一键同步
    handleAsync(){
      this.showSyncGroupGoodsModal = true
    },
    // 添加组
    addGroup() {
      // TODO 添加校验逻辑
      this.loading = true
      if (this.addGroupName === '' || this.addGroupName === null || this.addGroupPlaceholder === undefined) {
        this.addGroupName = this.addGroupPlaceholder
      }
      addGroup(this.addGroupName).then(() => {
        this.showAddGroup = false
        this.addGroupName = ''
        this.init()
      })
    },
    // 删除分组
    deleteGroup(id,name) {
      this.selectGroupId = id
      this.selectGroupName = name
      this.showDeleteGroupModal = true
    }
  }
}
</script>

<style scoped lang="scss">
.library {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background-color: #fafafa;
  ::v-deep .ant-card-body{
    padding: 12px;
  }
  .groupCard {
    width: 322px;
    height: 200px;
    &:hover:not(.active){
      ::v-deep .ant-card-head-title{
        color: $color-orange;
      }
    }
    img{
      width: 65px;
      height: 65px;
      margin: 5px 5px;
    }
  }
}
</style>
