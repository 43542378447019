<template>
  <a-modal
    :visible="visible"
    cancelText="取消"
    centered
    okText="确认"
    width="618px"
    @cancel="cancel"
    @ok="ok"
  >
    <h6>将已选取的 <span class="text-danger">{{ selectGroupList.length }}</span>  个分组同步</h6>
    <a-spin :spinning="loading" tip="Loading...">
      <div class="flex-row flex-col-center">
        <a-button
          :type="selectGroupList.length === 0 ? 'primary': 'info'"
          size="small"
          @click="handleSelectAll()">
          {{ selectGroupList.length === 0 ? '选择全部': '取消全部' }}</a-button>
        <template v-if="prodctCodes.length">
          <a-select v-model="selectCode" placeholder="请选择产品" size="small" style="width: 120px;margin-left: 15px;">
            <a-select-option v-for="(item, index) in prodctCodes" :key="index" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </template>
      </div>
      <div class="GroupList mt-10">
        <a-card
          v-for="(item, index) in GroupList"
          :key="index"
          :class="['groupCard','mr-10','mb-10', { active : selectGroupList.indexOf(item.id) !== -1 }]"
          style="width: 255px"
          @click="handleGroup(item.id)">
          <p class="groupCard-title">{{ item.name }}</p>
          <p class="groupCard-count">{{ `商品数量: ${item.count}` }}</p>
        </a-card>
        <strong v-if="GroupList.length === 0">暂无分组,请先创建分组</strong>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { getGroupList, syncGroupGoods } from '@/api/SelectGoods'
import ShowModalMixin from '@/mixin/ShowModalMixin'

/**
 * @author  XuHongli
 * @date  2022/12/8 14:36
 * @version 1.0
 * @description 同步商品弹窗
 */
export default {
  name: 'SyncGroupGoodsModal',
  mixins: [ShowModalMixin],
  data() {
    return {
      loading: false,
      // 分组列表
      GroupList: [],
      // 选择的分组
      selectGroupList: [],
      // V5商城-编码
      prodctCodes: [],
      selectCode: '',
    }
  },
  created() {
    this.init()
    this.initV5Shop()
  },
  methods: {
    initV5Shop () {
      if (!localStorage.getItem('v5_Shop')) return
      let v5_Shop = localStorage.getItem('v5_Shop') ? JSON.parse(localStorage.getItem('v5_Shop')) : []
      for (let item of v5_Shop) {
        this.prodctCodes.push({
          'label': item == '88000003' ? '商城' : '盲盒商城',
          'value': item
        })
      }
      this.selectCode = this.prodctCodes[0].value
    },
    init() {
      this.loading = true
      getGroupList().then((res) => {
        this.GroupList = res.data
        this.loading = false
      })
    },
    cancel() {
      this.closeModal()
    },
    handleGroup(GroupId) {
      if(this.selectGroupList.indexOf(GroupId) === -1){
        this.selectGroupList.push(GroupId)
      } else {
        this.selectGroupList.splice(this.selectGroupList.indexOf(GroupId),1)
      }
    },
    handleSelectAll() {
      if(this.selectGroupList.length === 0) {
        this.selectGroupList = this.GroupList.map((item) => {
          return item.id
        })
      }else {
        this.selectGroupList = []
      }
    },
    ok() {
      if(this.selectGroupList.length === 0){
        this.$notification.error({
          message: `同步失败`,
          description:
            `您还未选择分组.`,
        })
      } else {
        syncGroupGoods(this.selectGroupList, this.selectCode).then((res) => {
          this.$message.success(res.msg)
          this.selectGroupList = []
          this.closeModal()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.groupCard{
  .ant-card-body{
    padding: 10px;
  }
  &:hover:not(.active){
    background-color: #ededf0;
    .groupCard-title{
      color: $color-orange;
    }
  }
}
.groupCard.active{
  background-color: $color-orange;
  color: white;
}
.GroupList{
  background-color: #fafafa;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  max-height: 420px;
  overflow-y: scroll;
}
</style>
